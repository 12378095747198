/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { navLinkActiveFix } from '../helpers/navLinkSelector';

const TermsAndConditionsPage = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="center">Terms & Conditions</h1>
            <br />
            <Container style={{ fontSize: 14 }}>
                <p>
                    These Terms and Conditions (the “Contract”) are made
                    effective as of the date of booking between you, (the
                    “Client”) and Phoenix Fire & Performance Art ("Phoenix
                    Fire").
                </p>
                <p>
                    PERFORMANCE OF SERVICES. (1). Phoenix Fire shall arrive at
                    20 minutes before the starting time to set-up and inspect
                    the performance area. Phoenix Fire shall adapt performance
                    to suit the right mood of the event. Performances may be
                    performed with or without significant breaks, based on the
                    performer's judgement, and depending on the type of
                    performance being done. Phoenix Fire reserves the right to
                    alter or omit any part of the agreed performance if
                    conditions appear to be unsafe or in any way dangerous.
                </p>
                <p>
                    PAYMENT. Client will pay compensation to Phoenix Fire for
                    the services in the amount specified in the invoice. This
                    compensation shall be paid in full prior to the rendering of
                    performance services.
                </p>
                <p>
                    CANCELLATION POLICY. All deposit fees are non-refundable. A
                    minimum of 7 days notice will be required for cancellation
                    of this Contract. Any cancellation made with less than
                    7 days notice prior to the agreed upon service date will
                    result in full payment by Client. If the cancellation is
                    initiated by Phoenix Fire, all monies paid to Phoenix Fire
                    from Client shall be fully refunded, INCLUDING the deposit
                    fee. Refund shall be paid out at month's end.
                </p>
                <p>
                    TERM. Client and Phoenix Fire agree that this Contract shall
                    commence on the date specified in the invoice and terminate
                    after the services are rendered. Said agreement may be
                    extended and/or renewed by agreement of all parties in
                    writing thereafter.
                </p>
                <p>
                    RELATIONSHIP OF PARTIES. It is understood by the parties
                    that Phoenix Fire is an independent contractor with respect
                    to Client, and not an employee of Client.
                </p>
                <p>
                    FORCE MAJEURE. If performance of this Contract or any
                    obligation under this Contract is prevented, restricted, or
                    interfered with by causes beyond either party's reasonable
                    control ("Force Majeure"), and if the party unable to carry
                    out its obligations gives the other party prompt written
                    notice of such event, then the obligations of the party
                    invoking this provision shall be suspended to the extent
                    necessary by such event. The term Force Majeure shall
                    include, without limitation, acts of God, fire, explosion,
                    vandalism, storm or other similar occurrence, orders or acts
                    of military or civil authority, or by national emergencies,
                    insurrections, riots, or wars, or strikes, lock-outs, work
                    stoppages, other labor disputes, or supplier failures. The
                    excused party shall use reasonable efforts under the
                    circumstances to avoid or remove such causes of
                    non-performance and shall proceed to perform with reasonable
                    dispatch whenever such causes are removed or ceased. An act
                    or omission shall be deemed within the reasonable control of
                    a party if committed, omitted, or caused by such party, or
                    its employees, officers, agents, or affiliates.
                </p>
                <p>
                    DISPUTE RESOLUTION. The parties will attempt to resolve any
                    dispute arising out of or relating to this Contract through
                    friendly negotiations amongst the parties. If the matter is
                    not resolved by negotiation, the parties will resolve the
                    dispute using the below Alternative Dispute Resolution (ADR)
                    procedure.
                </p>
                <p>
                    Any controversies or disputes arising out of or relating to
                    this Contract will be submitted to mediation in accordance
                    with any statutory rules of mediation. If mediation is not
                    successful in resolving the entire dispute or is
                    unavailable, any outstanding issues will be submitted to
                    binding arbitration under the rules of the American
                    Arbitration Association. The arbitrator's award will be
                    final, and judgment may be entered upon it by any court
                    having proper jurisdiction.
                </p>
                <p>
                    ENTIRE CONTRACT. This Contract contains the entire Contract
                    of the parties, and there are no other promises or
                    conditions in any other contract whether oral or written
                    concerning the subject matter of this Contract. This
                    Contract supersedes any prior written or oral agreements
                    between the parties.
                </p>
                <p>
                    SEVERABILITY. If any provision of this Contract shall be
                    held to be invalid or unenforceable for any reason, the
                    remaining provisions shall continue to be valid and
                    enforceable. If a court finds that any provision of this
                    Contract is invalid or unenforceable, but that by limiting
                    such provision it would become valid and enforceable, then
                    such provision shall be deemed to be written, construed, and
                    enforced as so limited.
                </p>
                <p>
                    AMENDMENT. This Contract may be modified or amended in
                    writing, if the writing is signed by the parties obligated
                    under the amendment.
                </p>
                <p>
                    GOVERNING LAW. This Contract shall be governed by the laws
                    of the State of Florida.
                </p>
                <p>
                    NOTICE. Any notice or communication required or permitted
                    under this Contract shall be sufficiently given if delivered
                    in person or by certified mail, return receipt requested, to
                    the address set forth in the opening paragraph or to such
                    other address as one party may have furnished to the other
                    in writing.
                </p>
                <p>
                    WAIVER OF CONTRACTUAL RIGHT. The failure of either party to
                    enforce any provision of this Contract shall not be
                    construed as a waiver of limitation of that party's right to
                    subsequently enforce and compel strict compliance with every
                    provision of this Contract.
                </p>
                <p>
                    ASSIGNMENT. Neither party may assign or transfer this
                    Contract without the prior written consent of the
                    non-assigning party, which approval shall not be
                    unreasonably withheld.
                </p>
            </Container>
        </section>
    );
};

export default TermsAndConditionsPage;
