import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import fireService from '../images/fireService.jpeg';
import ledService from '../images/minified/ledService.jpeg';
import danceService from '../images/minified/danceService.jpeg';
import acroService from '../images/services/acro/tripleacrooutdoors.jpeg';

import { navLinkActiveFix } from '../helpers/navLinkSelector';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Services = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="mt-5 center">Services</h1>
            <div className="center">
                <h4 className="serviceBlurb">
                    We offer a variety of entertainment options to choose from.
                    Performances can be customized to the occasion and event
                    theme. Performance packages are available, and services can
                    be combined. Explore the categories below to get a quote and
                    to learn more about each performance!{' '}
                </h4>
            </div>
            <Container className="servicesContainer">
                <Row style={{ justifyContent: 'center' }}>
                    <Col sm={12} md={4} lg={4}>
                        <div className="vcenter serviceImg">
                            <a
                                href="/services/fire-performances"
                                onClick={() =>
                                    ReactGA.event({
                                        category: 'fire-perf-img',
                                        action: 'fire-perf-img',
                                        label: 'fire-perf-img'
                                    })
                                }
                            >
                                <img
                                    src={fireService}
                                    alt="fire fans elegant party"
                                    className="serviceTriplet"
                                />
                            </a>
                            <Link to="/services/fire-performances">
                                <Button
                                    size='lg'
                                    className="mt-4 mb-4"
                                    onClick={() =>
                                        ReactGA.event({
                                            category: 'fire-performance-btn',
                                            action: 'fire-performance-btn',
                                            label: 'fire-performance-btn'
                                        })
                                    }
                                >
                                    Fire Performances
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <div className="vcenter serviceImg">
                            <a
                                href="/services/dance-performances"
                                onClick={() =>
                                    ReactGA.event({
                                        category: 'dance-perf-img',
                                        action: 'dance-perf-img',
                                        label: 'dance-perf-img'
                                    })
                                }
                            >
                                <img
                                    src={danceService}
                                    alt="dance pose in yellow flower field"
                                    className="serviceTriplet"
                                />
                            </a>
                            <Link to="/services/dance-performances">
                                <Button
                                    size='lg'
                                    className="mt-4"
                                    onClick={() =>
                                        ReactGA.event({
                                            category: 'dance-performance-btn',
                                            action: 'dance-performance-btn',
                                            label: 'dance-performance-btn'
                                        })
                                    }
                                >
                                    Dance Performances
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <div className="vcenter serviceImg">
                            <a
                                href="/services/led-performances"
                                onClick={() =>
                                    ReactGA.event({
                                        category: 'led-perf-img',
                                        action: 'led-perf-img',
                                        label: 'led-perf-img'
                                    })
                                }
                            >
                                <img
                                    src={ledService}
                                    alt="led pin wheel"
                                    className="serviceTriplet"
                                />
                            </a>
                            <Link to="/services/led-performances">
                                <Button
                                    size='lg'
                                    className="mt-4"
                                    onClick={() =>
                                        ReactGA.event({
                                            category: 'led-performance-btn',
                                            action: 'led-performance-btn',
                                            label: 'led-performance-btn'
                                        })
                                    }
                                >
                                    LED Performances
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4}>
                        <div className="vcenter serviceImg">
                            <a
                                href="/services/acrobatic-performances"
                                onClick={() =>
                                    ReactGA.event({
                                        category: 'acro-perf-img',
                                        action: 'acro-perf-img',
                                        label: 'acro-perf-img'
                                    })
                                }
                            >
                                <img
                                    src={acroService}
                                    alt="acrobatic performers outdoors st pete"
                                    className="serviceTriplet"
                                />
                            </a>
                            <Link to="/services/acrobatic-performances">
                                <Button
                                    size='lg'
                                    className="mt-4"
                                    onClick={() =>
                                        ReactGA.event({
                                            category: 'acro-performance-btn',
                                            action: 'acro-performance-btn',
                                            label: 'acro-performance-btn'
                                        })
                                    }
                                >
                                    Acrobatic Performances
                                </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Services;
