import shortid from 'shortid';

const Card = (props) => {
    return (
        <div className="card h-100 bg-dark">
            <div className="card-body">
                <h5 className="card-title" align="center">{props.heading}</h5>
                {props.description.map((body) => (
                    <span key={shortid.generate()}>
                        <p className="card-text">&#8226; {body}</p>
                    </span>
                ))}
            </div>
            <div className="card-footer d-flex justify-content-between vcenter">
                <p>{props.price}{props.sale && <span className='pricetag'>{props.sale}</span>}</p>
                <a
                    href={props.url}
                    target="_blank"
                    rel="noreferrer"
                    className={
                        props.url !== ''
                            ? 'btn btn-primary btn-lg'
                            : 'btn btn-primary btn-lg disabled'
                    }
                >
                    <span className="ms-1">{'Sign Up'}</span>
                </a>
            </div>
        </div>
    );
};

export default Card;
