import Router from './components/Routes.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

const MEASUREMENT_ID = 'G-EQHKYTHMYL';
ReactGA.initialize(MEASUREMENT_ID);

const App = () => {
    return <Router />;
};

export default App;
