import { useEffect } from 'react';
import { Container, Row, Col, Button  } from 'react-bootstrap';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import { WorkbookCarousel } from '../components/Carousel';
import { workbookPages } from '../helpers/workbookPages';
import { Link } from 'react-router-dom';
import Card from '../components/Card';
import shortid from 'shortid';
import ReactGA from 'react-ga4';

const products = [
    {
        index: 2,
        heading: 'Tier 1',
        description: ['Guide to Gigging Workbook', '5 Integration Meetings (covering the 5 sections of the workbook)'],
        price: '$360',
        url: 'https://buy.stripe.com/6oE9D27jm0RVgDudQW'
    },
    {
        index: 3,
        heading: 'Tier 2',
        description: ['Guid to Gigging Workbook, ', '1 Integration Meeting', '1 Private Movement Lesson'],
        price: '$220',
        url: 'https://buy.stripe.com/bIYbLaeLO3033QIaEL'
    },
    {
        index: 4,
        heading: 'Tier 3',
        description: ['Guide to Gigging Workbook', '1 Integration Meeting'],
        price: '$155',
        url: 'https://buy.stripe.com/3cs7uUfPS3034UMcMU '

    },
    {
        index: 5,
        heading: 'Integration Meeting',
        description: ['Ask questions about the workbook content, get support on taking action steps, and talk through roadblocks.'],
        price: '$50 per 45 minutes',
        url: 'https://buy.stripe.com/fZe9D2avy7gjdridQZ'
    },
    {
        index: 6,
        heading: 'Private Movement Lessons',
        // eslint-disable-next-line max-len
        description: ['Topics for private lessons include choreography techniques, dance technique, prop/fire dance, embodiment, stage presence, and strengthening/balancing movement practices'],
        price: '$65 per 60 minutes',
        url: 'https://buy.stripe.com/3cs9D2fPS0RV1IA4gq'
    }
];

const featuredProduct = {
    index: 1,
    heading: 'Full Mentorship',
    description: [
        'Two 60min Private Movement Lessons (can be virtual or in-person if local)',
        'Five 45min Zoom Integration Meetings: covers the 5 sections of the workbook',
        'Guide to Gigging Workbook'
    ],
    price: '$450',
    url: 'https://buy.stripe.com/cN22aAgTW8kn72U005',
    sale: '10% OFF'
};

const RisingPhoenix = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="center">Rising Phoenix</h1>
            <div className='banner-wrapper'>
            </div>
            <h4 className="rising-blurb">
                Supporting rising performance artists through personalized
                mentorship, education, and training.{''}
            </h4>
            <Container className="center">
                <div className='mb-4 featured' key={featuredProduct.index}>
                    <Card className=''
                        heading={featuredProduct.heading}
                        description={featuredProduct.description}
                        url={featuredProduct.url}
                        price={featuredProduct.price}
                        sale={featuredProduct.sale}
                    />
                </div>
            </Container>
            <Container>
                <Row className='center'>
                    {products.map((product) => (
                        <Col lg={6} className='mb-4' key={shortid.generate()}>
                            <Card
                                heading={product.heading}
                                description={product.description}
                                url={product.url}
                                price={product.price}
                                key={shortid.generate()}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="vcenter mt-5">
                            <WorkbookCarousel
                                pages={workbookPages}
                            />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="center vcenter">
                            <h4 className="serviceBlurb">
                                In creativity, collaboration, and commitment, we
                                rise together!{' '}
                            </h4>

                            <Link to='/rising-phoenix/blog'>
                                <Button
                                    size="lg"
                                    onClick={() =>
                                        ReactGA.event({
                                            category:
                                                    'rising-phoenix-blog-btn',
                                            action: 'rising-phoenix-blog-btn',
                                            label: 'rising-phoenix-blog-btn'
                                        })
                                    }
                                >
                                        Visit Blog
                                </Button>
                            </Link>

                        </div>
                        <div className="center questions">
                            <h4 align='center'>
                                Questions? Contact us at{' '}
                                <span>
                                    <a
                                        href="mailto:rising@phoenixfireflow.com?subject=Questions about Rising Phoenix"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="a-link"
                                    >
                                        rising@phoenixfireflow.com
                                    </a>
                                </span>
                            </h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default RisingPhoenix;
