import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    return <div></div>;
};

export default RouteChangeTracker;
