import { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
    About,
    Home,
    Contact,
    RisingPhoenix,
    RisingPhoenixBlog,
    Services,
    AcrobaticPerformances,
    FirePerformances,
    LEDPerformances,
    DancePerformances,
    TermsAndConditions,
    NotFound,
    BlogPostPage,
    ReviewPage
    // ComingSoon,
} from '../pages';

import { blogData } from '../assets/blogData';
import Footer from './Footer';
import NavbarBar from './Navbar';
import { useLocation } from 'react-router';
import RouteChangeTracker from './RouteChange';
import shortid from 'shortid';

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

const RoutePoints = () => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <RouteChangeTracker />
            <ScrollToTop>
                <main className="App">
                    <NavbarBar />
                    <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route path="about" element={<About />}></Route>
                        <Route path="services" element={<Services />}></Route>
                        <Route
                            path="rising-phoenix"
                            element={<RisingPhoenix />}
                        ></Route>
                        <Route
                            path="rising-phoenix/blog"
                            element={<RisingPhoenixBlog />}
                        ></Route>
                        <Route path="contact" element={<Contact />}></Route>
                        <Route
                            path="services/fire-performances"
                            element={<FirePerformances />}
                        ></Route>
                        <Route
                            exact
                            path="services/led-performances"
                            element={<LEDPerformances />}
                        ></Route>
                        <Route
                            path="services/dance-performances"
                            element={<DancePerformances />}
                        ></Route>
                        <Route
                            path="services/acrobatic-performances"
                            element={<AcrobaticPerformances />}
                        ></Route>
                        <Route
                            path="/review"
                            element={<ReviewPage />}
                        ></Route>
                        <Route
                            path="terms-and-conditions"
                            element={<TermsAndConditions />}
                        ></Route>
                        {blogData.map((blogEntry) => {
                            return (
                                <Route path={`rising-phoenix/blog/${blogEntry.url}`} key={shortid.generate()} element={<BlogPostPage blogEntry={blogEntry}/>}/>
                            );
                        })}
                        <Route
                            path="*"
                            element={<NotFound />}
                        ></Route>
                        {/* <Route
                            path="comingSoon"
                            element={<ComingSoon />}
                        ></Route> */}
                    </Routes>
                    <Footer />
                </main>
            </ScrollToTop>
        </Router>
    );
};

export default RoutePoints;
