import { Container, Row, Col, Button } from 'react-bootstrap';
import shortid from 'shortid';

const BlogPost = ({ blogEntry }) => {

    const paragraphs = [];

    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

    const blogParagraphs = () => {
        const pArr = blogEntry.content.split('\n\n');
        pArr.map((p) => {
            paragraphs.push(p.trim());
        });
    };

    blogParagraphs();

    return (
        <section>
            <article>
                <div className="center">
                    <h1 align='center' className='blog-title'>{blogEntry.title}</h1>
                </div>

                <Container className='p-4'>
                    <Row>
                        <Col>
                            <p className='mt-5'>{blogEntry.date}</p>
                            <br/>
                            <br/>
                            <div>{paragraphs.map((p) => {
                                if (numbers.includes(p[0])){
                                    return (
                                        <p className='indent-list' key={shortid.generate()}>{p}</p>
                                    );
                                } else {
                                    return <p key={shortid.generate()}>{p}</p>;
                                }
                            })}</div>
                            <div className="center mt-5"><a href={blogEntry.link}><Button>{blogEntry.btnText}</Button></a></div>
                        </Col>
                    </Row>
                </Container>
            </article>
        </section>
    );
};

export default BlogPost;
