import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { videoLinksLED } from '../helpers/videoLinks';
import { testimonials } from '../helpers/testimonials';
import { CarouselPage, TestimonialCarousel } from '../components/Carousel';
import { Link } from 'react-router-dom';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import ReactGA from 'react-ga4';

const LEDPerformances = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="mt-5 center">LED Performances</h1>
            <div className="vcenter mb-5">
                <div>
                    <h4 className="serviceBlurb">
                        {
                            // eslint-disable-next-line max-len
                            'LED performers are a unique and captivating way to light up the night! Performers can be roaming or perform a choreographed show. Our performers train in acrobatics, dance and prop manipulation to deliver a high caliber performance.'
                        }
                    </h4>
                </div>
                <div>
                    <h4 className="serviceBlurb">
                        {
                            'Fire & LED make for a beautiful combination and a popular package performance!'
                        }
                    </h4>
                </div>
            </div>
            <Container>
                <Row>
                    <Col>
                        <h1 className="center">Video Gallery</h1>
                        <CarouselPage videos={videoLinksLED} controls={false} />
                    </Col>
                </Row>
                <div className="center">
                    <Link to="/contact">
                        <Button
                            size="lg"
                            className="mt-5 mb-5"
                            onClick={() =>
                                ReactGA.event({
                                    category: 'led-perf-book-now',
                                    action: 'led-perf-book-now',
                                    label: 'led-perf-book-now'
                                })
                            }
                        >
                            Book Now!
                        </Button>
                    </Link>
                </div>

                {/* <div>
                    <GalleryPage photos={photosArr} />
                </div> */}
                <TestimonialCarousel testimonials={testimonials} />
            </Container>
        </section>
    );
};

export default LEDPerformances;
