import img1 from '../images/blog/pricing-your-art.jpeg';

export const blogData = [
    {
        title: 'Putting a tag on it: challenges of pricing your art and offerings',
        content: `You’ve done the hard part already, the most important work of creating is complete - so give yourself (and your cast if you have one) a hug and a deep breath.

        Now comes the part of getting your creative offering out to the world!

        When you create something really unique - and there’s no exact industry standard that fits the bill - it can be challenging to put a price tag on your offering. On the one hand, you want to value your work for what it’s worth. On the other hand, you want it to be accessible to your audience. Your price will probably fluctuate as you find the right balance - and that’s okay! #notestoself

        Cheers to us for paving a path and creating something boldly unique. It’s not the easiest path, but it’s a fruitful one.

        Regarding my Rising Phoenix Mentorship - I’ve lowered the price of joining for the whole program. Now there are also tiers where you can take parts of the program and pay less - adding on the rest whenever feels best. I know the content in this program is facking awesome. Totally worth the last price tag I had on it. A lot of stuff I wish I knew when I was starting out in the performing world. And - I’m listening! I want this program to be accessible to rising artists. The more people move through this program, the better - for everyone.

        It’s like having the fancy plates on display behind a glass case that no one is allowed to touch. Wouldn’t you rather feed your friends and families on fancy plates?

        Sometimes you need to move your offerings out from behind the locked glass case by making them available and accessible. Always honor the value of your work - and know that sometimes that valuing comes by doing what you can to make sure it can be received.

        Here are 5 ideas to help you value your offerings:

        1. Listen to your body. Sit with different price points and notice how your body responds. Shift through your reactions and find the psychosomatic ground that feels solid underneath you

        2. Ask your audience what their budget would be for something like you’re wanting to offer.

        3. If you have multiple services as part of your offering, estimate the price of each of those services individually and add em up.

        4. Don’t forget additional costs. The opportunity cost here can be thought of as the hours (and the money that you could be making with those hours) that you are sacrificing in order to give your time to this offering. Renting space? Membership platforms? Cost of equipment? Safety personnel? Fuel? Stripe? All the little things tend to add up. Your time is valuable!

        5. Pay yourself first. What is the minimum amount of money that you want to make? Factor that in and don’t touch it!
`,
        preview: 'You’ve done the hard part already, the most important work of creating is complete - so give yourself (and your cast if you have one) a hug and a deep breath.',
        url: 'pricing-your-art',
        img: img1,
        date: 'September 18, 2022',
        btnText: 'Take Me To Rising Phoenix',
        link: '/rising-phoenix'
    }
];

