import { useEffect } from 'react';
import splashPhoto from '../images/minified/splash.JPG';
import homeTriplet1 from '../images/samiFans.JPG';
import homeTriplet2 from '../images/minified/burnoff.jpeg';
import homeTriplet3 from '../images/minified/ediHoop.JPG';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TestimonialCarousel } from '../components/Carousel';
import { testimonials } from '../helpers/testimonials';
import logo from '../images/phoenixLogoTrans.png';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import ReactGA from 'react-ga4';
import RouteChangeTracker from '../components/RouteChange';

const Home = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <Container>
                <Row>
                    <Col className="center">
                        <div className="splashWrapper">
                            <img
                                className="img-fluid splashImage"
                                src={splashPhoto}
                                alt="Fire dancer with fire fans"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <RouteChangeTracker />
            <div className="homeContent">
                <div className="mt-4">
                    <h1 className="tagLine">{'Ignite Memories'}</h1>
                </div>
                <div className="mt-4">
                    <h4 className="text-center tagBlurb">
                        {
                            'Bringing contemporary fire dancing to our communities through performance, collaboration, and education'
                        }
                    </h4>
                </div>
                <div className="mt-4">
                    <Link to="/contact">
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={() =>
                                ReactGA.event({
                                    category: 'home-book-now-btn',
                                    action: 'home-book-now-btn',
                                    label: 'home-book-now-btn'
                                })
                            }
                        >
                            Book Now!
                        </Button>{' '}
                    </Link>
                </div>
            </div>
            <Container className="contentStrip">
                <Row>
                    <Col className="vcenter">
                        <h4 className="homeBlurb">
                            {
                                // eslint-disable-next-line max-len
                                'At Phoenix Fire, we strive to maintain the highest levels of artistry and professionalism in our field. Since our founding in 2015, our performers have lit up a diverse range of events including private parties, weddings, business celebrations, art galas and more. We have diverse movement backgrounds and years of experience as professional entertainers. '
                            }
                        </h4>
                        <Link to="/about">
                            <Button
                                className="mt-3"
                                size="lg"
                                onClick={() =>
                                    ReactGA.event({
                                        category: 'home-about-btn',
                                        action: 'home-about-btn',
                                        label: 'home-about-btn'
                                    })
                                }
                            >
                                About Us
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5 homeTripletContainer">
                <Row className="justify-content-md-between gx-5 gy-5">
                    <Col xs={12} md={4} lg={4} className="center">
                        <img
                            className="homeTriplet"
                            src={homeTriplet1}
                            alt="hooded fire fans"
                        />
                    </Col>
                    <Col xs={12} md={4} lg={4} className="center">
                        <img
                            className="homeTriplet"
                            src={homeTriplet2}
                            alt="dragonstaff burnoff"
                        />
                    </Col>
                    <Col xs={12} md={4} lg={4} className="center">
                        <img
                            className="homeTriplet"
                            src={homeTriplet3}
                            alt="face paint fire hoop"
                        />
                    </Col>
                </Row>
            </Container>
            <div className="center mt-4">
                <Link to="/services">
                    <Button
                        className="mt-3"
                        size="lg"
                        onClick={() =>
                            ReactGA.event({
                                category: 'home-services-btn',
                                action: 'home-services-btn',
                                label: 'home-services-btn'
                            })
                        }
                    >
                        View Services
                    </Button>
                </Link>
            </div>
            <Container className='mt-5'>
                <Row>
                    <Col md={12} lg={6}>
                        <div className="vcenter">
                            <img
                                src={logo}
                                alt="contact us"
                                className="contact-image"
                            />
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <TestimonialCarousel testimonials={testimonials} />
                    </Col>
                </Row>
            </Container>
            <Container className="quoteStrip">
                <Row>
                    <Col className="center">
                        <h4 className="quote">
                            “Heat is practically a sacrament for me, and
                            watching a fire is one of life’s miraculous
                            pleasures. Fire has fabulous movement and spatial
                            invention. It is constantly altering, never
                            repeating itself….In its embrace, particularly after
                            a day of despondency, I feel capable of anything.”
                            <div className="indent">
                                <h4>- Twyla Tharp</h4>
                            </div>
                        </h4>
                        {/* <div className="center mt-3 mb-5">
                            <h1>Follow Along</h1>
                        </div>
                        <div className="center" style={{ height: '1000px' }}>
                            <iframe
                                allowtransparency="true"
                                id="iframe"
                                src="https://www.instagram.com/phoenixfireflow/embed"
                                frameborder="0"
                                style={{
                                    overflow: 'hidden',
                                    height: '100%',
                                    width: '80vw',
                                }}
                                title="Phoenix Fire Instagram Feed"
                            ></iframe>
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Home;
