import Homepage from './Home';
import AcrobaticsPage from './AcrobaticPerformances';
import AboutPage from './About';
import ServicesPage from './Services';
import ContactPage from './Contact';
import FirePerformancePage from './FirePerformances';
import LEDPerformancePage from './LEDPerformances';
import DancePerformancePage from './DancePerformances';
import RisingPhoenixPage from './RisingPhoenix';
import RisingPhoenixBlogPage from './RisingPhoenixBlog';
import TermsAndConditionsPage from './TermsAndConditions';
import Page404 from './Page404';
import BlogPost from './BlogPost';
import LeaveReview from './LeaveReview';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
    return(
        <>
            <Helmet>‍
                <title>Phoenix Fire & Performance Art • Ignite Memories</title>‍
                <meta name="title" content="Phoenix Fire & Performance Art • Ignite Memories" />
                <meta name="description" content="Phoenix Fire & Performance Art is a group of multidisciplinary fire dancers based in St Petersburg and Tampa Bay, Florida. Offering fire dancing, fire eating, acrobatics, LED performances, and combination packages. Phoenix Fire performs at corporate events, weddings, private events and more." />
                <meta property="og:title" content="Phoenix Fire & Performance Art • Ignite Memories" />
                <meta property="og:description" content="Phoenix Fire & Performance Art is a group of multidisciplinary fire dancers based in St Petersburg and Tampa Bay, Florida. Offering fire dancing, fire eating, acrobatics, LED performances, and combination packages. Phoenix Fire performs at corporate events, weddings, private events and more." />
                <meta property="og:url" content="https://phoenixfireflow.com/" />
                <meta property="og:site_name" content="Phoenix Fire & Performance Art" />
            </Helmet>
            <Homepage />
        </>
    );
};

export const AcrobaticPerformances = () => {
    return(
        <>
            <Helmet>‍
                <title>Acrobatic Performances | Phoenix Fire</title>‍
                <meta name="title" content="Acrobatic Performances | Phoenix Fire" />
                <meta name="description" content="Our acrobats are artful, precise and captivating in their performances! Acrobats are typically roaming performers, or can be a beautiful addition to a fire, dance, or LED show. We perform in Tampa, St. Petersburg, and all throughout Florida!" />
                <meta property="og:title" content="Acrobatic Performances • Phoenix Fire" />
                <meta property="og:description" content="Our acrobats are artful, precise and captivating in their performances! Acrobats are typically roaming performers, or can be a beautiful addition to a fire, dance, or LED show. We perform in Tampa, St. Petersburg, and all throughout Florida!" />
                <meta property="og:url" content="https://phoenixfireflow.com/services/acrobatic-performances" />
                <meta property="og:site_name" content="Acrobatic Performances • Phoenix Fire" />
            </Helmet>
            <AcrobaticsPage />
        </>
    );
};

export const About = () => {
    return(
        <>
            <Helmet>‍
                <title>About | Phoenix Fire</title>‍
                <meta name="title" content="About | Phoenix Fire" />
                <meta name="description" content="Phoenix Fire is a group of fire dancers, LED dancers, acrobats, and movement professionals in St Petersburg, Florida. We are available for corporate events, weddings, and private events throughout Florida and beyond." />
                <meta property="og:title" content="About • Phoenix Fire" />
                <meta property="og:description" content="Phoenix Fire is a group of fire dancers, LED dancers, acrobats, and movement professionals in St Petersburg, Florida. We are available for corporate events, weddings, and private events throughout Florida and beyond." />
                <meta property="og:url" content="https://phoenixfireflow.com/about" />
                <meta property="og:site_name" content="About • Phoenix Fire" />
            </Helmet>
            <AboutPage />
        </>
    );
};

export const FirePerformances = () => {
    return(
        <>
            <Helmet>‍
                <title>Fire Performances | Phoenix Fire</title>‍
                <meta name="title" content="Fire Performances | Phoenix Fire" />
                <meta name="description" content="Fire dancing and fire performing are our specialty! Add on acrobatics or fire eating to any fire performance. Based in St Petersburg, Florida. Solo and group performances are available for a variety of event types." />
                <meta property="og:title" content="Fire Performances • Phoenix Fire" />
                <meta property="og:description" content="Fire dancing and fire performing are our specialty! Add on acrobatics or fire eating to any fire performance. Based in St Petersburg, Florida. Solo and group performances are available for a variety of event types." />
                <meta property="og:url" content="https://phoenixfireflow.com/services/fire-performances" />
                <meta property="og:site_name" content="Fire Performances • Phoenix Fire" />
            </Helmet>
            <FirePerformancePage />
        </>
    );
};

export const LEDPerformances = () => {
    return(
        <>
            <Helmet>‍
                <title>LED Performances | Phoenix Fire</title>‍
                <meta name="title" content="LED Performances | Phoenix Fire" />
                <meta name="description" content="LED dancers and glow dancers are a great addition to any event. LED performers can be roaming/ambient, or deliver a fully choreographed show. Packages are available and customizable with other services!" />
                <meta property="og:title" content="LED Performances • Phoenix Fire" />
                <meta property="og:description" content="LED dancers and glow dancers are a great addition to any event. LED performers can be roaming/ambient, or deliver a fully choreographed show. Packages are available and customizable with other services!" />
                <meta property="og:url" content="https://phoenixfireflow.com/services/led-performances" />
                <meta property="og:site_name" content="LED Performances • Phoenix Fire" />
            </Helmet>
            <LEDPerformancePage />
        </>
    );
};

export const DancePerformances = () => {
    return(
        <>
            <Helmet>‍
                <title>Dance Performances | Phoenix Fire</title>‍
                <meta name="title" content="Dance Performances | Phoenix Fire" />
                <meta name="description" content="Add an elegant touch to any event with ballet and contemporary dancers. Dance performers can be roaming or ambient, and costumes/themes can be created to fit the theme of each event." />
                <meta property="og:title" content="Dance Performances • Phoenix Fire" />
                <meta property="og:description" content="Add an elegant touch to any event with ballet and contemporary dancers. Dance performers can be roaming or ambient, and costumes/themes can be created to fit the theme of each event." />
                <meta property="og:url" content="https://phoenixfireflow.com/services/dance-performances" />
                <meta property="og:site_name" content="Dance Performances • Phoenix Fire" />
            </Helmet>
            <DancePerformancePage />
        </>
    );
};

export const Contact = () => {
    return(
        <>
            <Helmet>‍
                <title>Contact | Phoenix Fire</title>‍
                <meta name="title" content="Contact | Phoenix Fire" />
                <meta name="description" content="Contact Phoenix Fire & Performance Art for a quote for fire dancers, LED dancers, acrobatics and more! Serving St Petersburg, Florida and the Tampa Bay area. Available for hire throughout Florida and beyond!" />
                <meta property="og:title" content="Contact • Phoenix Fire" />
                <meta property="og:description" content="Contact Phoenix Fire & Performance Art for a quote for fire dancers, LED dancers, acrobatics and more! Serving St Petersburg, Florida and the Tampa Bay area. Available for hire throughout Florida and beyond!" />
                <meta property="og:url" content="https://phoenixfireflow.com/contact" />
                <meta property="og:site_name" content="Contact • Phoenix Fire" />
            </Helmet>
            <ContactPage />
        </>
    );
};

export const Services = () => {
    return(
        <>
            <Helmet>‍
                <title>Services | Phoenix Fire</title>‍
                <meta name="title" content="Services | Phoenix Fire" />
                <meta name="description" content="Phoenix Fire & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:title" content="Services • Phoenix Fire" />
                <meta property="og:description" content="Phoenix Fire & Performance Art offers fire dancing, fire eating, LED/glow performances, dance performers, and acrobats. Serving Tampa Bay FL, St. Petersburg FL, and beyond." />
                <meta property="og:url" content="https://phoenixfireflow.com/services" />
                <meta property="og:site_name" content="Services • Phoenix Fire" />
            </Helmet>
            <ServicesPage />
        </>
    );
};

export const RisingPhoenix = () => {
    return(
        <>
            <Helmet>‍
                <title>Rising Phoenix | Phoenix Fire</title>‍
                <meta name="title" content="Rising Phoenix | Phoenix Fire" />
                <meta name="description" content="Supporting rising performance artists and fire dancers through personalized mentorship and education. Bring your creative business to life with personalized guidance, resources, and coaching." />
                <meta property="og:title" content="Rising Phoenix • Phoenix Fire" />
                <meta property="og:description" content="Supporting rising performance artists and fire dancers through personalized mentorship and education. Bring your creative business to life with personalized guidance, resources, and coaching." />
                <meta property="og:url" content="https://phoenixfireflow.com/rising-phoenix" />
                <meta property="og:site_name" content="Rising Phoenix • Phoenix Fire" />
            </Helmet>
            <RisingPhoenixPage />
        </>
    );
};

export const BlogPostPage = ({ blogEntry }) => {
    const getShortname = () => {
        const shortNameArr = blogEntry.url.split('-');
        const shortName = [];
        shortNameArr.map((word) => {
            shortName.push(word.charAt(0).toUpperCase() + word.slice(1));
        });
        return(shortName.join(' '));
    };

    const blogUrl = `https://phoenixfireflow.com/rising-phoenix/blog/${blogEntry.url}`;

    return(
        <>
            <Helmet>‍
                <title>{`${getShortname()} | Rising Phoenix Blog`}</title>‍
                <meta name="title" content={`${getShortname()  } | Rising Phoenix Blog`} />
                <meta name="description" content={blogEntry.preview} />
                <meta property="og:title" content={`${getShortname()  } | Rising Phoenix Blog`} />
                <meta property="og:description" content={blogEntry.preview} />
                <meta property="og:url" content={{ blogUrl }} />
                <meta property="og:site_name" content={`${getShortname()  } | Rising Phoenix Blog`} />
                <script type="application/ld+json">
                    {`{ "@context":"https://schema.org", "@type":"BlogPosting", "author":{ "@type":"Person", "name":"Sami M.", "url":"https://www.phoenixfireflow.com/about" }, "dateModified":"2022-09-18T13:30:02.231Z", "datePublished":"2022-09-18T04:00:00.000Z", "headline": "${blogEntry.title}", "mainEntityOfPage":{ "@id": "${blogUrl}", "@type":"itemPage", "url": "${blogUrl}" }, "description": "${blogEntry.preview}", "url": "${blogUrl}", "image":{ "@type":"ImageObject", "url": "${blogEntry.img}", "width":"769", "height":"928" }, "publisher":{ "@type":"Organization", "name":"Rising Phoenix Blog", "url": "https://www.phoenixfireflow.com/rising-phoenix" } }`}
                </script>
            </Helmet>
            <BlogPost blogEntry={blogEntry} />
        </>
    );
};

export const RisingPhoenixBlog = () => {
    return(
        <>
            <Helmet>‍
                <title>Rising Phoenix Blog | Phoenix Fire</title>‍
                <meta name="title" content="Rising Phoenix Blog | Phoenix Fire" />
                <meta name="description" content="Visit the Rising Phoenix blog for tips on running a creative business, fire performing professionally, and more." />
                <meta property="og:title" content="Rising Phoenix Blog • Phoenix Fire" />
                <meta property="og:description" content="Visit the Rising Phoenix blog for tips on running a creative business, fire performing professionally, and more." />
                <meta property="og:url" content="https://phoenixfireflow.com/rising-phoenix/blog" />
                <meta property="og:site_name" content="Rising Phoenix Blog • Phoenix Fire" />
            </Helmet>
            <RisingPhoenixBlogPage />
        </>
    );
};
export const TermsAndConditions = () => {
    return(
        <>
            <Helmet>‍
                <title>Terms and Conditions | Phoenix Fire</title>‍
                <meta name="title" content="Terms and Conditions | Phoenix Fire" />
                <meta name="description" content="Phoenix Fire terms and conditions for booking" />
                <meta property="og:title" content="Terms and Conditions • Phoenix Fire" />
                <meta property="og:description" content="Phoenix Fire terms and conditions for booking" />
                <meta property="og:url" content="https://phoenixfireflow.com/terms-and-conditions" />
                <meta property="og:site_name" content="Terms and Conditions • Phoenix Fire" />
            </Helmet>
            <TermsAndConditionsPage />
        </>
    );
};
export const ReviewPage = () => {
    return(
        <>
            <Helmet>‍
                <title>Review | Phoenix Fire</title>‍
                <meta name="title" content="Review" />
                <meta name="description" content="Leave us a review!" />
                <meta property="og:title" content="Review" />
                <meta property="og:description" content="Leave us a review!" />
            </Helmet>
            <LeaveReview />
        </>
    );
};
export const NotFound = () => {
    return(
        <>
            <Helmet>‍
                <title>Error Page Not Found</title>‍
                <meta name="title" content="Error Page Not Found" />
                <meta name="description" content="404 error page not found" />
                <meta property="og:title" content="Error Page Not Found" />
                <meta property="og:description" content="404 error page not found" />
            </Helmet>
            <Page404 />
        </>
    );
};
