import { Container, Row, Col } from 'react-bootstrap';

import facebook from '../images/footer/minified/fb-icon.png';
import instagram from '../images/footer/minified/insta-icon.png';
import mail from '../images/footer/minified/mail-icon.png';

const Footer = () => {
    return (
        <footer id="footer">
            <Container>
                <Row className="align-items-center">
                    <Col className="col-4 d-flex align-items-center">
                        <div className="footer-wrapper">
                            <h5 className="footer-text">
                                {'Phoenix Fire & Performance Art © 2022'}
                            </h5>
                            <div className="signature">
                                <span>
                                    <a
                                        href="https://anthonygress.dev"
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        Website Built by Anthony Gress
                                    </a>
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col className="col-4">
                        <div className="center gigsalad">
                            <a
                                href="https://www.gigsalad.com/Phoenix_Fire_Performance_Art"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src="https://cress.gigsalad.com/images/svg/standalone/promokit-links/best-of/white/best-of--white.svg"
                                    alt="Best of GigSalad Fire Performers"
                                    height="128"
                                    width="128"
                                />
                            </a>
                        </div>
                    </Col>
                    <Col className="col-4">
                        <div className="d-flex justify-content-end icons">
                            <div>
                                <a href="https://www.gigsalad.com/Phoenix_Fire_Performance_Art">
                                    <span>
                                        <img
                                            src="https://cress.gigsalad.com/images/svg/standalone/promokit-links/social/social--blue--square--small.svg"
                                            alt="Find on GigSalad"
                                            // height="32"
                                            // width="32"
                                            className="footer-icon gigsalad-footer-icon"
                                        />
                                    </span>
                                </a>
                                <a
                                    href="https://facebook.com/phoenixfirefl"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>
                                        <img
                                            src={facebook}
                                            alt="facebook logo"
                                            className="footer-icon fb-icon"
                                        />
                                    </span>
                                </a>
                            </div>
                            <div>
                                <a
                                    href="https://www.instagram.com/phoenixfireflow"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>
                                        <img
                                            src={instagram}
                                            alt="instagram logo"
                                            className="footer-icon"
                                        />
                                    </span>
                                </a>
                                <a
                                    href="mailto:booking@phoenixfireflow.com?subject=Message from Phoenix Fire website"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span>
                                        <img
                                            src={mail}
                                            alt="mail logo"
                                            className="footer-icon"
                                        />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
