import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Page404 = () => {

    return (
        <section>
            <h1 className="center">Page Not Found</h1>
            <br />
            <div className="mt-5">
                <h2 className="center">Go back to the homepage?</h2>
                <div className="center mt-5">
                    <Link to='/'><Button size='lg'>Home</Button></Link>
                </div>
            </div>
        </section>
    );
};

export default Page404;
