/* eslint-disable max-len */
export const testimonials = [
    {
        index: 0,
        text: 'They did an amazing job! All the guests were so blown away by Phoenix Fire. They were so professional. I would 100% recommend them to anyone looking for fire performers.',
        author: '- Maggie, Useppa Island Club (Useppa Island, Florida)'
    },
    {
        index: 1,
        text: 'Guests are asking when will they return. I hope to have Sami and her LED performers back for many performances in the future!!!! Ladies arrived early, were professional, beautiful LED performance, after the show they were greeting/ speaking with guests. Could not have been any better, looking forward to future events with them.',
        author: '- Michelle, Marker8 Tiki Bar (Dunedin, Florida)'
    },
    {
        index: 2,
        text: 'Phoenix Fire dancers are phenomenal! I had the fire dance and LED package. Myself and all guests were so impressed. This is one of the memories that I will have forever, sharing my birthday and experience with my friends and family. The music was hypnotic and thrilling. Highly recommend Phoenix fire dancers for most any age and special event.',
        author: '- Jayme (Tampa, Florida)'
    },
    {
        index: 3,
        text: 'Had a birthday dinner for my sister, and fire dancing by Phoenix Fire & Performance Art was our choice for entertainment. They exceeded every expectation we had! Sami & Partner were absolutely amazing... I highly recommend',
        author: '- Kenyan (Sarasota, Florida)'
    },
    {
        index: 4,
        text: 'Loved these fire performers. It was such a unique and special way to celebrate my dear friend’s birthday. They captured our attention beautifully!',
        author: '- Laura (Orlando, Florida)'
    },
    {
        index: 5,
        text: 'She did a great job, took our vision and made it happen!',
        author: '- Tracey, AUDI Dealership (Tampa, Florida)'
    },
    {
        index: 6,
        // eslint-disable-next-line quotes
        text: "My guests can't stop talking about how wonderful these fire dancers were!",
        author: '- Janet (Anna Maria, Florida)'
    }
];
