import { Container, Row, Col } from 'react-bootstrap';
import shortid from 'shortid';

// Collage Gallery Items
const GalleryCol = (props) => {
    // console.log(props.group);
    return (
        <Col
            xs={6}
            sm={6}
            md={4}
            lg={3}
            className="d-flex flex-column"
            style={{ maxWidth: '350px' }}
            key={props.group}
        >
            {props.group.map((photo) => (
                <img
                    src={photo}
                    alt=""
                    className="img-fluid m-1"
                    key={shortid.generate()}
                    // data-index={firePhotos.index}
                />
            ))}
        </Col>
    );
};

export const GalleryPage = (props) => {
    return (
        <>
            <Container>
                <Row>
                    <GalleryCol group={props.photos[0]} />
                    <GalleryCol group={props.photos[1]} />
                    <GalleryCol group={props.photos[2]} />
                    <GalleryCol group={props.photos[3]} />
                </Row>
            </Container>
        </>
    );
};
