import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GalleryPage } from '../components/Gallery';
import { TestimonialCarousel } from '../components/Carousel';
import { testimonials } from '../helpers/testimonials';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import ReactGA from 'react-ga4';

import dance1 from '../images/services/dance/contemp:ballet1.jpeg';
import dance2 from '../images/services/dance/contemp:ballet2.jpeg';
import dance3 from '../images/services/dance/contemp:ballet3.jpeg';
import dance4 from '../images/services/dance/contemp:ballet4.jpeg';
import dance5 from '../images/services/dance/contemp:ballet5.jpeg';
import dance6 from '../images/services/dance/contemp:ballet6.jpeg';
import dance7 from '../images/services/dance/contemp:ballet7.jpeg';
import dance8 from '../images/services/dance/hoopdance.jpeg';

const dancePhotos1 = [dance8, dance2];
const dancePhotos2 = [dance5, dance6];
const dancePhotos3 = [dance3, dance4];
const dancePhotos4 = [dance7, dance1];

const photosArr = [dancePhotos1, dancePhotos2, dancePhotos3, dancePhotos4];

const DancePerformances = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="mt-5 center">Dance Performances</h1>
            <div className="vcenter">
                <h4 className="serviceBlurb">
                    {
                        // eslint-disable-next-line max-len
                        'Our dancers have professional training in ballet, acrobatics, contemporary, jazz, and modern dance forms. We provide ambient dancing and choreographed shows. Dancers can utilize props like hoops, flowers, silk fans and ribbons; and costuming can match the colors and theme of your event. Some ideas for dance performances are adding an elegant touch to a wedding entrance, highlighting a musical act, or treating your guests to a dance lesson!'
                    }
                </h4>
            </div>

            <Container>
                <Row>
                    <Col>
                        {/* <h2 className="center">Video Gallery</h2> */}
                        {/* <Carousel /> */}
                    </Col>
                </Row>
                <div className="center">
                    <Link to="/contact">
                        <Button
                            size="lg"
                            className="mt-5 mb-5"
                            onClick={() =>
                                ReactGA.event({
                                    category: 'dance-perf-book-now',
                                    action: 'dance-perf-book-now',
                                    label: 'dance-perf-book-now'
                                })
                            }
                        >
                            Book Now!
                        </Button>
                    </Link>
                </div>

                <div>
                    <GalleryPage photos={photosArr} />
                </div>
                <TestimonialCarousel testimonials={testimonials} />
            </Container>
        </section>
    );
};

export default DancePerformances;
