import { Carousel, Container } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

export const CarouselPage = (props) => {
    return (
        <ResponsiveCarousel infiniteLoop showThumbs={false} emulateTouch={true}>
            {props.videos.map((video) => (
                <div key={video.index}>
                    <div className="center">{video.link}</div>
                </div>
            ))}
        </ResponsiveCarousel>
    );
};

export const WorkbookCarousel = (props) => {
    return (
        <Carousel
            variant="light"
            interval={4000}
            className="workbook-carousel"
        >
            {props.pages.map((page) => (
                <Carousel.Item key={page.index} className="carouselSlide">
                    <div className="center img-wrapper">{page.link}</div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export const TestimonialCarousel = (props) => {
    return (
        <>
            <h1 className="mt-5 center testimonial-header">Testimonials</h1>
            <Carousel variant="light" indicators={true} controls={false} className='testimonial-carousel'>
                {props.testimonials.map((testimonial) => (
                    <Carousel.Item
                        key={testimonial.text}
                        className="carouselSlide"
                    >
                        <Container
                            className="testimonial-card"
                            key={testimonial.text}
                        >
                            <div className="center testimonial-card-wrapper">
                                <p className="center testimonial-card-content fs-4">{testimonial.text}</p>
                            </div>
                            <div className='testimonial-author-wrapper'>
                                <p className="testimonial-author fs-4">
                                    {testimonial.author}
                                </p>
                            </div>
                        </Container>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
};

export default CarouselPage;
