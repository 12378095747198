import page1 from './workbookPages/page1.jpg';
import page2 from './workbookPages/page2.jpg';
import page3 from './workbookPages/page3.jpg';
import page4 from './workbookPages/page4.jpg';
import page5 from './workbookPages/page5.jpg';
import page6 from './workbookPages/page6.jpg';

export const workbookPages = [
    {
        index: 0,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page1}
                alt="Rising phoenix workbook page"
            />
        )
    },
    {
        index: 1,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page2}
                alt="Rising phoenix workbook page"
            />
        )
    },
    {
        index: 2,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page3}
                alt="Rising phoenix workbook page"
            />
        )
    },
    {
        index: 3,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page4}
                alt="Rising phoenix workbook page"
            />
        )
    },
    {
        index: 4,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page5}
                alt="Rising phoenix workbook page"
            />
        )
    },
    {
        index: 5,
        link: (
            <img
                className="img-fluid workbook-page"
                src={page6}
                alt="Rising phoenix workbook page"
            />
        )
    }
];
