import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import { blogData } from '../assets/blogData';
import { Link } from 'react-router-dom';
import shortid from 'shortid';


const RisingPhoenixBlogPage = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 align="center">Rising Phoenix Blog</h1>
            <Container className='mt-5'>
                {blogData.map((blog) => {
                    return (
                        <Row key={shortid.generate()} className='mt-5'>
                            <Col xs={12} md={4} className='center' >
                                <Link to={`/rising-phoenix/blog/${blog.url}`}>
                                    <div className="blog-img"><img src={blog.img} alt="blog post image" className='responsive-img' /></div>
                                </Link>
                            </Col>
                            <Col xs={12} md={8} className='center' >
                                <div className='blog-overview p-1'>
                                    <h6 className={'mt-5'}>{blog.date}</h6>
                                    <h2 className='mt-4'>{blog.title}</h2>
                                    <h5 className='mt-4'>{blog.preview}</h5>
                                    <div className="center mt-4">
                                        <Link to={`/rising-phoenix/blog/${blog.url}`}>
                                            <Button size='lg'>Read More</Button>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    );
                })}
            </Container>
        </section>
    );
};

export default RisingPhoenixBlogPage;
