/* eslint-disable no-unused-vars */

/* eslint-disable max-len */
import { Navbar, Nav, Container, NavDropdown, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { HamburgerSpinReverse } from 'react-animated-burgers';
import { useState, useEffect } from 'react';
import { navLinkActiveFix } from '../helpers/navLinkSelector';

export const NavbarBar = () => {
    const [isOpen, setOpen] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

    useEffect(() => {
        setOpen(false);
        if (isOpen){
            document.querySelector('.navbar-toggler-icon').click();
        }
    }, [isMobile]);

    useEffect(() => {
        navLinkActiveFix();
    });

    const checkMobileNav = () => {
        if (isMobile || isOpen){
            return('ms-auto align-items-center mt-5');
        } else {
            return ('ms-auto align-items-center pb-0');
        }
    };

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            bg="light"
            className={isOpen ? 'bg-dark pt-3 pb-4' : 'bg-black gradient pt-3 pb-4'}
            style={{ marginTop: '-1px' }}
            fixed="top"
        >
            <Container style={{ maxWidth: '90vw !important' }}>
                <LinkContainer to="/">
                    <Navbar.Brand role="button" onClick={() => window.scrollTo(0, 0)}>
                        <span className={isOpen ? 'fs-4 navBrand colored-text' : 'fs-4 navBrand'}>{'PHOENIX FIRE'}</span>
                    </Navbar.Brand>
                </LinkContainer>
                {isMobile && <HamburgerSpinReverse isActive={isOpen} barColor={isOpen ? '#de9917' : 'black'} className='hamburger-menu' buttonWidth={35}/>}
                <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ left: '81.5%', top: '.75rem', position: 'absolute', padding: '10px' }} onClick={() => isMobile && setOpen(!isOpen)}></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav
                        className={checkMobileNav()}
                        defaultActiveKey="/home"
                    >
                        <LinkContainer to="/" eventKey="home">
                            <Nav.Link
                                onClick={() => isMobile && setOpen(!isOpen)}
                                eventKey="home"
                                className={isOpen ?'fs-5 home navLink colored-text':'fs-5 home navLink colored-text-black'}
                            >
                                Home
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about">
                            <Nav.Link
                                onClick={() => isMobile && setOpen(!isOpen)}
                                eventKey="about"
                                className={isOpen ?'fs-5 about navLink colored-text':'fs-5 about navLink colored-text-black'}
                            >
                                About
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/services">
                            <Nav.Link
                                onClick={() => isMobile && setOpen(!isOpen)}
                                eventKey="services"
                                className={isOpen ?'fs-5 services navLink colored-text':'fs-5 services navLink colored-text-black'}
                            >
                                Services
                            </Nav.Link>
                        </LinkContainer>
                        <NavDropdown title="Rising Phoenix" id={isMobile && isOpen ? 'nav-dropdown-colored' : 'nav-dropdown-black' } className={isOpen ? 'fs-5 rising-phoenix navLink colored-text' : 'fs-5 rising-phoenix navLink colored-text-black'} onClick={() => {
                            setTimeout(() => {
                                navLinkActiveFix();
                            }, 5);
                        }}>
                            <LinkContainer to='rising-phoenix' onClick={() => isMobile && setOpen(!isOpen)} ><NavDropdown.Item eventKey="program">The Program</NavDropdown.Item></LinkContainer>
                            <LinkContainer to='rising-phoenix/blog' onClick={() => isMobile && setOpen(!isOpen)}><NavDropdown.Item eventKey="blog">Blog</NavDropdown.Item></LinkContainer>
                        </NavDropdown>
                        {/* <LinkContainer to="/rising-phoenix">
                            <Nav.Link
                                onClick={() => isMobile && setOpen(!isOpen)}
                                eventKey="rising-phoenix"
                                className={isOpen ? 'fs-5 rising-phoenix navLink colored-text' : 'fs-5 rising-phoenix navLink colored-text-black'}
                            >
                                Rising Phoenix
                            </Nav.Link>
                        </LinkContainer> */}
                        <LinkContainer to="/contact">
                            <Nav.Link
                                onClick={() => isMobile && setOpen(!isOpen)}
                                eventKey="contact"
                                className={isOpen ?'fs-5 contact navLink colored-text':'fs-5 contact navLink colored-text-black'}
                            >
                                Contact
                            </Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarBar;
