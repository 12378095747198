import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { CarouselPage, TestimonialCarousel } from '../components/Carousel';
import { GalleryPage } from '../components/Gallery';
import { videoLinksFire } from '../helpers/videoLinks';
import { testimonials } from '../helpers/testimonials';
import { Link } from 'react-router-dom';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import ReactGA from 'react-ga4';

import fire1 from '../images/services/fire/fireperformance1.jpeg';
import fire3 from '../images/services/fire/fireperformance3.jpeg';
import fire4 from '../images/services/fire/anthonystaffvertical.jpeg';
import fire5 from '../images/services/fire/fireperformance5.jpeg';
import fire6 from '../images/services/fire/fireperformance6.jpeg';
import fire7 from '../images/services/fire/fireperformance7.jpeg';
import fire8 from '../images/services/fire/anthonyeating.jpeg';
import fire9 from '../images/services/fire/EddieAlien.jpeg';

const firePhotos1 = [fire5, fire7];
const firePhotos2 = [fire3, fire9];
const firePhotos3 = [fire1, fire6];
const firePhotos4 = [fire4, fire8];
const photosArr = [firePhotos1, firePhotos2, firePhotos3, firePhotos4];

const FirePerformances = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="mt-5 center">Fire Performances</h1>
            <div className="vcenter mb-5">
                <h4 className="serviceBlurb">
                    Fire dancing is our specialty! Our performers have spent years
                    perfecting their skills and deliver a captivating performance. This
                    performance includes multiple fire apparatuses performed with
                    precision, skill, and grace. Add on fire eating, juggling, or
                    acrobatics for even more dramatic effect! We offer services from
                    a single fire dancer to a choreographed show with several
                    performers!{' '}
                </h4>
            </div>

            <Container>
                <Row>
                    <Col>
                        <h1 className="center">Video Gallery</h1>
                        <CarouselPage videos={videoLinksFire} />
                    </Col>
                </Row>
                <div className="center">
                    <Link to="/contact">
                        <Button
                            size="lg"
                            className="mt-5 mb-5"
                            onClick={() =>
                                ReactGA.event({
                                    category: 'fire-perf-book-now',
                                    action: 'fire-perf-book-now',
                                    label: 'fire-perf-book-now'
                                })
                            }
                        >
                            Book Now!
                        </Button>
                    </Link>
                </div>

                <div>
                    <GalleryPage photos={photosArr} />
                </div>
                <TestimonialCarousel testimonials={testimonials} />
            </Container>
        </section>
    );
};

export default FirePerformances;
