export const videoLinksFire = [
    {
        index: 0,
        link: (
            <iframe
                src="https://player.vimeo.com/video/556381796?h=cff24339c4"
                className="iframeCenter"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="0"
            ></iframe>
        )
    },
    {
        index: 1,
        link: (
            <iframe
                src="https://player.vimeo.com/video/662780943?h=acd61f8096"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="1"
            ></iframe>
        )
    },
    {
        index: 2,
        link: (
            <iframe
                src="https://player.vimeo.com/video/556385113?h=59bd9e3d59"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="2"
            ></iframe>
        )
    },
    {
        index: 3,
        link: (
            <iframe
                src="https://player.vimeo.com/video/556584720?h=1e7ae8d714"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="3"
            ></iframe>
        )
    },
    {
        index: 4,
        link: (
            <iframe
                src="https://player.vimeo.com/video/556574763?h=7f228daca5"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="4"
            ></iframe>
        )
    }
];

export const videoLinksLED = [
    {
        index: 0,
        link: (
            <iframe
                src="https://player.vimeo.com/video/742285266?h=e781b09980"
                width="640"
                height="300"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="0"
            ></iframe>
        )
    }
];
