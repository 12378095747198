import { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import samiarabesque from '../images/minified/arabesquesami.jpeg';
import anthonydouble from '../images/anthonydoublestaff.JPG';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const About = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <Container>
                <div className="aboutImgWrapper">
                    <div className="aboutContainer"></div>
                </div>
                <div className="aboutWrapper">
                    <div className="aboutTextOverlay bg-dark">
                        <p>
                            Phoenix Fire is a multidisciplinary company of
                            performance artists based in St Petersburg, Florida.
                        </p>
                        <p>
                            We perform at private and community events,
                            including parties, weddings, festivals, art shows,
                            business celebrations, and corporate events.
                        </p>
                        <p>
                            We are available to perform throughout the state of
                            Florida and internationally!
                        </p>
                        <p>
                            <a href="/services/fire-performances">
                                Fire dancing
                            </a>{' '}
                            is our specialty, and we have several options for
                            customizing these performances. We also offer
                            customizable{' '}
                            <a href="/services/led-performances">
                                LED performances
                            </a>
                            ,{' '}
                            <a href="/services/dance-performances">
                                ballet and contemporary dance
                            </a>
                            , ambient performing, and{' '}
                            <a href="/rising-phoenix">
                                training/mentoring opportunities
                            </a>{' '}
                            for aspiring performers .
                        </p>
                        <div className="center mt-4">
                            <Link to="/services">
                                <Button
                                    variant="primary"
                                    size="lg"
                                    onClick={() =>
                                        ReactGA.event({
                                            category: 'about-view-services',
                                            action: 'about-view-services',
                                            label: 'about-view-services'
                                        })
                                    }
                                >
                                    View Services
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
            <div className="meetCreators">
                <h1 className="text-center">Meet the Creators</h1>
            </div>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div>
                            <div className="center">
                                <img
                                    className="aboutCreators"
                                    src={anthonydouble}
                                    alt="anthony double staff"
                                />
                            </div>
                            <h1 className="mt-4 text-center">
                                Anthony Gress: Founder
                            </h1>
                            <div className="center">
                                <div className="aboutText">
                                    <p>
                                        Anthony has been practicing flow arts
                                        and fire dancing for 9 years. He was
                                        first introduced to flow arts with a
                                        pair of poi made from socks, which were
                                        gifted to him by a close friend in
                                        Orlando, Florida. His passion for these
                                        arts grew over the years, and inspired
                                        him to share the art form with others.
                                    </p>
                                    <p>
                                        Anthony has several years of experience
                                        providing fire and LED performances for
                                        private and community events. He has
                                        performed alongside musical artists such
                                        as Random Rab, Papadosio, The Polish
                                        Ambassador, and Rising Appalachia. His
                                        performance style is fluid and grounded,
                                        drawing influence from tai-chi, yoga and
                                        modern dance. He has trained with
                                        artists such as Aileen Lawlor, KC Allen,
                                        Matt Ruffner, Krisstina Hawks and
                                        Skiffle Hazlitt.
                                    </p>
                                    <p>
                                        By day, Anthony lives in Saint
                                        Petersburg, FL and is a software
                                        engineer and a certified yoga
                                        instructor. He coded this website from
                                        the ground up! He is thrilled to bring
                                        performances to his community and bring
                                        opportunities to rising artists.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="center">
                            <img
                                className="aboutCreators"
                                src={samiarabesque}
                                alt="sami arabesque"
                            />
                        </div>
                        <h1 className="mt-4 text-center">
                            Sami Mangione: Creative Director
                        </h1>
                        <div className="center">
                            <div className="aboutText">
                                <p>
                                    Sami came into the world of fire dancing
                                    with an extensive background in competitive
                                    gymnastics and dance. When she discovered
                                    the creative possibilities of flow arts, she
                                    dove into the community.
                                </p>
                                <p>
                                    Sami has her BA in Dance from the University
                                    of Florida, and was granted several
                                    performance opportunities throughout her
                                    college career with the university and
                                    through Phoenix. In addition to flow arts,
                                    she has studied acrobatics, contemporary
                                    dance, ballet, and West African dance.
                                </p>
                                <p>
                                    Currently, she serves as creative director
                                    for Phoenix, where she handles booking,
                                    hires performers, choreographs shows, and
                                    directs{' '}
                                    <Link to="/rising-phoenix">
                                        {'Rising Phoenix'}
                                    </Link>
                                    .
                                </p>
                                <p>
                                    She is also the founder of{' '}
                                    <a
                                        href="https://wildhavensomatics.com"
                                        target="_blank"
                                        rel="noopened noreferrer"
                                        onClick={() =>
                                            ReactGA.event({
                                                category:
                                                    'about-wild-haven-btn',
                                                action: 'about-wild-haven-btn',
                                                label: 'about-wild-haven-btn'
                                            })
                                        }
                                    >
                                        Wild Haven Somatics
                                    </a>
                                    , where she teaches creative and
                                    contemplative mind-body practices.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
