export const navLinkActiveFix = () => {
    const homeNavLink = document.querySelector('.home');
    const aboutNavLink = document.querySelector('.about');
    const servicesNavLink = document.querySelector('.services');
    const risingPhoenixNavLink = document.querySelector('.dropdown-toggle');
    const contactNavLink = document.querySelector('.contact');

    const navLinks = [
        homeNavLink,
        aboutNavLink,
        servicesNavLink,
        risingPhoenixNavLink,
        contactNavLink
    ];
    const path = window.location.pathname;

    navLinks.forEach(navLink => {
        navLink.classList.remove('active');
    });

    if (path.includes('services')) {
        servicesNavLink.classList.add('active');
    } else if (path.includes('about')) {
        aboutNavLink.classList.add('active');
    } else if (path.includes('rising-phoenix')) {
        risingPhoenixNavLink.classList.add('active');
    } else if (path.includes('contact')) {
        contactNavLink.classList.add('active');
    } else if (path === '/') {
        homeNavLink.classList.add('active');
    }
};
