import { useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GalleryPage } from '../components/Gallery';
import { TestimonialCarousel } from '../components/Carousel';
import { testimonials } from '../helpers/testimonials';
import { navLinkActiveFix } from '../helpers/navLinkSelector';
import ReactGA from 'react-ga4';

import acro1 from '../images/services/acro/acroTriple.jpeg';
import acro2 from '../images/services/acro/doubleAcroFold.jpeg';
import acro3 from '../images/services/acro/michaelhulahandstand.jpeg';
import acro4 from '../images/services/acro/michaelstiltshula.jpeg';
import acro5 from '../images/services/acro/morphsuitmichaelacro.jpeg';
import acro6 from '../images/services/acro/tripleacrooutdoors.jpeg';
import acro7 from '../images/services/acro/tripleacropark.jpeg';
import acro8 from '../images/services/acro/tripleacrotreepose.jpeg';

const acroPhotos1 = [acro8, acro4];
const acroPhotos2 = [acro3, acro6];
const acroPhotos3 = [acro2, acro5];
const acroPhotos4 = [acro7, acro1];

const photosArr = [acroPhotos1, acroPhotos2, acroPhotos3, acroPhotos4];

const AcrobaticPerformances = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    return (
        <section>
            <h1 className="mt-5 center">Acrobatic Performances</h1>
            <div className="vcenter">
                <h4 className="serviceBlurb">
                    {
                        // eslint-disable-next-line max-len
                        'Our acrobats are artful, precise and captivating in their performances! Acrobats are typically roaming performers, or can be a beautiful addition to a fire, dance, or LED show.'
                    }
                </h4>
            </div>

            <Container>
                <div className="center">
                    <Link to="/contact">
                        <Button
                            size="lg"
                            className="mt-5 mb-5"
                            onClick={() =>
                                ReactGA.event({
                                    category: 'acro-perf-book-now',
                                    action: 'acro-perf-book-now',
                                    label: 'acro-perf-book-now'
                                })
                            }
                        >
                            Book Now!
                        </Button>
                    </Link>
                </div>

                <div>
                    <GalleryPage photos={photosArr} />
                </div>
                <TestimonialCarousel testimonials={testimonials}/>
            </Container>
        </section>
    );
};

export default AcrobaticPerformances;
