import { useState, useRef, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import contactPhoto from '../images/phoenixLogoTrans.png';
// import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import { navLinkActiveFix } from '../helpers/navLinkSelector';

const ContactPage = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    const form = useRef();
    const [verified, setVerified] = useState(false);
    const onVerify = () => {
        setVerified(true);
    };
    // const test = async () => {
    //     const formData = new FormData(form.current).entries();
    //     const response = await fetch('/.netlify/functions/hello-world/', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         mode: 'cors',
    //         body: JSON.stringify(Object.fromEntries(formData)),
    //     });

    //     const data = await response.json();
    //     console.log(data);
    // };

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData(form.current).entries();

            await fetch('/.netlify/functions/email-domain', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
                body: JSON.stringify(Object.fromEntries(formData))
            });

            // const data = await response.json();
            // console.log(data);

            // emailjs.sendForm(
            //     'service_dqx0qqt',
            //     'template_wrz1j76',
            //     form.current,
            //     'user_LCeauX4RMI8720l37xEgk'
            // );

            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Message Sent!',
                text: 'Thank you for requesting a quote! We will get back to you with a quote shortly you via email',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            // console.log("SUCCESS!", response.status, response.text);
        } catch (error) {
            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Whoops!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        e.target.reset();
    };
    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} lg={6} className="contact-content">
                        <Container>
                            <h1 className="heading" align="center">
                                Contact Us
                            </h1>
                        </Container>
                        <Container className="mb-5">
                            <div className="vcenter">
                                <h4>
                                    {
                                        'Thank you for your interest in our company! Please fill out the form below to receive a quote for your event.'
                                    }
                                </h4>
                                <div style={{ marginTop: '1.5rem' }}>
                                    <h4>
                                        {
                                            'In order to maintain the highest standards of service and ensure optimal performance outcomes, our Phoenix Fire is currently only accepting a select number of new clients.'
                                        }
                                    </h4>
                                </div>
                            </div>
                        </Container>
                        <Form
                            ref={form}
                            onSubmit={sendEmail}
                            className="text-dark"
                        >
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicName"
                            >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Name"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="First Last"
                                        name="name"
                                        autoComplete="name"
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        name="email"
                                        autoComplete="email"
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formQuestions"
                            >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Performance Type"
                                    className="mb-3 w-75 mx-auto "
                                >
                                    <Form.Select name="performanceType">
                                        <option>Fire Performances</option>
                                        <option>LED Performances</option>
                                        <option>Acrobatic Performances</option>
                                        <option>Dance Performances</option>
                                        <option>Combination Package</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Occasion"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Birthday Party, Corporate Gala, Luau"
                                        name="eventType"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Event Date"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="date"
                                        placeholder="5/25/22"
                                        name="date"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Event Address"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="address"
                                        placeholder="Private residence in Sarasota, FL"
                                        name="address"
                                        autoComplete="address-line1"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Event City"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="city"
                                        placeholder="Sarasota, FL"
                                        name="city"
                                        autoComplete="on"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Event State"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="state"
                                        placeholder="FL"
                                        name="state"
                                        autoComplete="on"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Number of Guests"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="30-40"
                                        name="guestCount"
                                        required
                                    />
                                </FloatingLabel>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Budget"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="$500 - $700"
                                        name="budget"
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formComment"
                            ></Form.Group>
                            <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Comments/Details"
                                className="w-75 mx-auto"
                            >
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    name="message"
                                    style={{ height: '100px' }}
                                />
                            </FloatingLabel>
                            <div className="mt-4 center">
                                <Reaptcha
                                    sitekey="6LfHmvUfAAAAAFGg1Z3tVI3qO1WX3XWSmD3x3uuo"
                                    onVerify={onVerify}
                                />
                            </div>
                            <Container className="center mt-4">
                                <Button
                                    type="submit"
                                    size="lg"
                                    className="w-75 mb-4"
                                    disabled={!verified}
                                >
                                    Send
                                </Button>
                            </Container>
                        </Form>
                    </Col>
                    <Col md={12} lg={6}>
                        <Container className="center">
                            <div className="vcenter">
                                <img
                                    src={contactPhoto}
                                    alt="contact us"
                                    className="contact-image"
                                />
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {/* <Button onClick={() => test()}>Test ME</Button> */}
        </section>
    );
};

export default ContactPage;
