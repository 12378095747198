import { useState, useRef, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FloatingLabel
} from 'react-bootstrap';
import contactPhoto from '../images/phoenixLogoTrans.png';
// import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import { navLinkActiveFix } from '../helpers/navLinkSelector';

const LeaveReview = () => {
    useEffect(() => {
        navLinkActiveFix();
    });

    const form = useRef();
    const [verified, setVerified] = useState(false);
    const onVerify = () => {
        setVerified(true);
    };
    // const test = async () => {
    //     const formData = new FormData(form.current).entries();
    //     const response = await fetch('/.netlify/functions/hello-world/', {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         mode: 'cors',
    //         body: JSON.stringify(Object.fromEntries(formData)),
    //     });

    //     const data = await response.json();
    //     console.log(data);
    // };

    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData(form.current).entries();

            await fetch('/.netlify/functions/email-review', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                mode: 'cors',
                body: JSON.stringify(Object.fromEntries(formData))
            });

            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Message Sent!',
                text: 'Thank you for your review!',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
            // console.log("SUCCESS!", response.status, response.text);
        } catch (error) {
            Swal.fire({
                customClass: {
                    title: 'swal2-title'
                },
                title: 'Whoops!',
                text: 'Something went wrong.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
        e.target.reset();
    };
    return (
        <section>
            <Container>
                <Row>
                    <Col xs={12} lg={6} className="contact-content">
                        <Container>
                            <h1 className="heading" align="center">
                                Leave a Review
                            </h1>
                        </Container>
                        <Container className="mb-5">
                            <div className="center">
                                <h4>
                                    {
                                        'Thank you for booking Phoenix Fire! Please leave your review below.'
                                    }
                                </h4>
                            </div>
                        </Container>
                        <Form
                            ref={form}
                            onSubmit={sendEmail}
                            className="text-dark"
                        >
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicName"
                            >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Name"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="First Last"
                                        name="name"
                                        autoComplete="name"
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email address"
                                    className="mb-3 w-75 mx-auto"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                        name="email"
                                        autoComplete="email"
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Review/Comments"
                                className="w-75 mx-auto"
                            >
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a review here"
                                    name="review"
                                    style={{ height: '100px' }}
                                />
                            </FloatingLabel>
                            <div className="mt-4 center">
                                <Reaptcha
                                    sitekey="6LfHmvUfAAAAAFGg1Z3tVI3qO1WX3XWSmD3x3uuo"
                                    onVerify={onVerify}
                                />
                            </div>
                            <Container className="center mt-4">
                                <Button
                                    type="submit"
                                    size="lg"
                                    className="w-75 mb-4"
                                    disabled={!verified}
                                >
                                    Send
                                </Button>
                            </Container>
                        </Form>
                    </Col>
                    <Col md={12} lg={6}>
                        <Container className="center">
                            <div className="vcenter">
                                <img
                                    src={contactPhoto}
                                    alt="contact us"
                                    className="contact-image"
                                />
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {/* <Button onClick={() => test()}>Test ME</Button> */}
        </section>
    );
};

export default LeaveReview;
